.fixed-header {
  position: sticky;
  top: 0;
  background-color: #fff; /* Adjust based on your theme */
  z-index: 1000; /* Ensure it appears above other content */
}

.fixed-title {
  position: sticky;
  top: 0;
  background-color: #fff; /* Adjust based on your theme */
  z-index: 1000; /* Ensure it appears above other content */
  padding: 10px 0;
  text-align: center;
}

.table-container {
  max-height: 400px; /* Adjust based on your needs */
  overflow: auto;
}
