.hedaer-container {
  display: flex;
  padding: 0px 15px;
  width: 100%;
  /* display="flex" sx={{ px: "15px", width: "100%" }} */
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-content {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  font-size: 30px;
  margin-top: 18px;
  letter-spacing: 1px;
  /* color: #fff; */
  /* background-color: #76b3d8; */
  background-color: #fff;
  color: #000;
}
/* .header-content p {
  padding: 0px;
} */
.header-logo {
  width: 226px;
  background: #fff;
}

/* .forms-container .css-1ie2wfi {
  background-color: #fff !important;
} */

@media screen and (max-width: 768px) {
  .hedaer-container {
    padding: 0px;
    text-align: center;
  }
  .header-wrapper {
    display: flex;
    flex-direction: column;
  }
  .header-logo {
    width: 100%;
    background: #fff;
  }
  .header-content {
    text-align: center;
    padding: 10px 15px;
    min-height: 55px;
    margin-top: 0px;
  }
}
