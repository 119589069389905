/* .ql-toolbar.ql-snow {
  float: left;
  width: 60px;
}

.ql-container.ql-snow {
  margin-left: 70px;
  width: calc(100% - 70px);
}

.ql-toolbar.ql-snow button {
  display: block;
  width: 100%;
  padding: 8px 0;
  text-align: center;
}

.ql-toolbar.ql-snow .ql-picker {
  display: block;
  width: 100%;
}

.ql-editor {
  min-height: 100px;
} */

.message-content p {
  margin-bottom: 0px;
  margin: 0;
}

.rce-mbox-text:after {
  display: none !important;
}

.message-content p:nth-of-type(2) {
  display: none; /* Make the <p> tags inline */
  margin: 0; /* Remove default margins */
}

/* .rce-mbox-text {
  background-color: blue;
} */

/* .rce-mbox {
  background-color: aqua;
} */

.rce-mbox.rce-mbox-right {
  background-color: #007aff;
}

.rce-mbox.rce-mbox-right .message-content {
  color: white;
}

.rce-mbox.rce-mbox-right .rce-mbox-time.non-copiable:before {
  color: white;
}

.rce-mbox-right-notch {
  fill: #007aff;
}

.ql-toolbar.ql-snow {
  border: 0;
}

/* .chat-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.chat-list-item .right-aligned-status {
  margin-left: 5px;
}

.rce-citem-body--bottom-title {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
